var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-header"},[_c('img',{staticClass:"img-fluid filter icon-fill",attrs:{"src":require("@/assets/images/arrow-left.svg"),"alt":"Logo de Geor Company","width":"24"},on:{"click":_vm.goToMobile}}),_c('span',{staticClass:"content-header__title"},[_vm._v(" Cambiar contraseña ")])]),_c('v-row',{staticClass:"content-profile"},[_c('v-col',{staticStyle:{"width":"468","max-width":"468px"}},[_c('v-card',{staticStyle:{"box-shadow":"none","background-color":"white"}},[_c('v-card-text',{staticClass:"card-password"},[(_vm.messageBackend)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.messageBackend))]):_vm._e(),_c('ValidationObserver',{ref:"formChangePassword",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form-password",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[(!_vm.user.hasPassword)?_c('ValidationProvider',{attrs:{"name":"código de verificación","rules":"required|digits:6","vid":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],staticClass:"profile",attrs:{"label":"Código de verificación","error-messages":errors,"counter":6},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})]}}],null,true)}):_vm._e(),(_vm.user.hasPassword)?_c('ValidationProvider',{attrs:{"name":"contraseña anterior","rules":"required|min:6|max:50","vid":"old_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"profile",staticStyle:{"font-size":"14px !important"},attrs:{"label":"Contraseña actual","error-messages":errors,"type":"password"},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8|password_strong|max:50","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"profile",staticStyle:{"font-size":"14px !important"},attrs:{"label":"Nueva contraseña","error-messages":errors,"type":_vm.showPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPassword)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye.svg"),"alt":"Ojo abierto"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye-close.svg"),"alt":"Ojo cerrado"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})]},proxy:true}],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('div',[_c('v-btn',{staticClass:"me-5",staticStyle:{"padding":"0px 10px","border-radius":"5px","height":"32px"},attrs:{"color":"primary","rounded":"","disabled":invalid || _vm.loading,"loading":_vm.loading,"type":"submit","depressed":""}},[_c('span',{staticClass:"text-button-new"},[_vm._v("Cambiar contraseña")])])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }