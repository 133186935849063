<template>
  <div
    class="business-none"
    v-if="user"
    style="display: grid; justify-content: center; align-content: center; height: 95vh"
  >
    <img src="@/assets/images/bot.svg" alt="bot" style="margin-inline: auto; margin-bottom: 8px" />
    <h6 class="mb-3" style="color: black">Crear una empresa</h6>
    <p>
      <small class="font-16" style="color: black">La creación tomará unos segundos </small>
    </p>
    <v-btn
      color="primary"
      depressed
      style="
        border-radius: 10px;
        padding: 8px, 16px, 8px, 16px !important;
        height: 40px !important;
        width: max-content;
        margin: auto;
      "
      @click="isOpenModalCreateBusiness = true"
      :disabled="!user.isValidated"
    >
      <small>
        <img src="@/assets/images/plus.svg" alt="plus" />
      </small>
      <span class="ms-2 text-button-new">Crear</span>
    </v-btn>
    <br />
    <!-- <br /> -->
    <span style="max-width: 342px" v-if="!user.isValidated"
      >Para crear tu empresa, primero debes confirmar la dirección de correo suministrada.</span
    >
    <ModalCreateBusiness
      @onAddProject="onAddProject"
      :is-open.sync="isOpenModalCreateBusiness"
    ></ModalCreateBusiness>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalCreateBusiness from "./ModalCreateBusiness.vue";

export default {
  components: {
    ModalCreateBusiness,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      isOpenModalCreateBusiness: false,
      baseUrl: process.env.VUE_APP_URL_WEB,
    };
  },
  methods: {
    onAddProject() {
      this.$emit("success");
    },
  },
};
</script>
