<template>
  <v-main class="bg-body-color main">
    <div style="justify-content: flex-start;" class="wrapper__auth-new">
      <div class="auth__box">
        <span class="title__auth">{{getFirstName(user.full_name)}}: ¿Quieres eliminar tu cuenta?</span>
        <p class="auth__box-p" style="margin-bottom: 16px !important;">Tu cuenta de Geor se desactivará durante
          <span style="font-weight: 700;">30 días</span>, puedes reactivarla en cualquier momento.</p>
        <div>
          <label>Después de <span style="font-weight: 700;">30 días</span></label>
          <ul style="display: grid; gap: 16px; padding-top: 8px;">
            <li>
              No podrás iniciar sesión ni usar los servicios de Geor con esta cuenta
            </li>
            <li>
              Perderás accesos a todas tus empresas
            </li>
            <li>
              Se borrará toda la información almacenada de tus empresas. No podrás descargarlos después de eliminar la cuenta
            </li>
            <li>
              No podrás recibir un reembolso por los servicios o productos que hayas comprado en Geor
            </li>
          </ul>
          <div style="display: grid; padding-top: 16px;">
            <label>Tu cuenta y los datos a asociados se eliminarán de forma definitiva</label>
            <label>¿Quieres continuar?</label>
          </div>
        </div>
        <v-btn
            @click="goToProfileDeleteCode"
            depressed
            type="submit"
            block
            class="v-btn-pad-x-large2" style="border-radius: 5px !important; background-color: #D92D20; color: white; margin-top: 16px;"
            ><span class="text-button-new">
              Continuar
            </span></v-btn>
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL_WEB,
      form: {
        code: '',
      },
      showVerifyModal: false,
    };
  },
  mounted() {

  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      countries: (state) => state.countries,
      user: (state) => state.user,
    }),
  },
  methods: {
    getFirstName(fullName) {
      // Dividir la cadena por espacios y devolver la primera palabra
      if (fullName) {
        return fullName.split(" ")[0];
      }
      return '';
    },

    openVerifyModal() {
      this.showVerifyModal = true;
    },
    onSubmit() {},
    onResendCode() {},
    goToProfileDeleteCode() {
      this.$router.push({ name: 'ProfileDeleteCode' });
    },
  },
};
</script>
