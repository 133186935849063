<template>
  <div>
    <v-dialog :value="isOpen" width="700" persistent scrollable>
      <v-card>
        <v-card-title>
          <div class="card-title">
            <span>Usuarios asociados al proyecto</span>
            <v-btn icon @click="onClose">
              <small><i class="fa fa-times"></i></small>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-right">#</th>
                  <th>NOMBRES</th>
                  <th>EMAIL</th>
                  <th>TELÉFONO</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(u, i) in users"
                  :key="i"
                  :class="{ 'is-info': u.is_owner }"
                >
                  <td class="text-right">{{ i + 1 }}</td>
                  <td>{{ u.full_name }}</td>
                  <td>{{ u.email }}</td>
                  <td>{{ u.phone || "---" }}</td>
                  <td class="text-center">
                    <v-btn
                      icon
                      depressed
                      v-if="!u.is_owner"
                      @click="onOpenModalDelete(u)"
                    >
                      <v-icon size="14" color="error">fa-trash</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ModalDelete
      :show.sync="openModalDelete"
      :title="titleModalDelete"
      :message="messageDelete"
      @onDeleteProject="onDestroy"
    ></ModalDelete>
  </div>
</template>

<script>
import ModalDelete from "@/components/partials/ModalDelete.vue";

export default {
  components: {
    ModalDelete,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    project: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    users: [],
    openModalDelete: false,
    titleModalDelete: "Eliminar usuario",
    messageDelete: "",
  }),
  watch: {
    isOpen(value) {
      if (value && this.project) {
        this.onFetchData();
      }
    },
  },
  methods: {
    onOpenModalDelete(user) {
      this.user = { ...user };
      this.messageDelete = `Está acción no se puede deshacer. ¿estás seguro de eliminar al usuario ${user.full_name}?`;
      this.openModalDelete = true;
    },
    onDestroy() {
      this.$axios
        .delete(`projects/${this.project.id}/users/${this.user.id}/delete`)
        .then((response) => {
          this.users = this.users.filter((u) => u.id !== this.user.id);
          this.openModalDelete = false;
          this.$toast.success(response.data.message);
        });
    },
    onFetchData() {
      this.$axios.get(`projects/${this.project.id}/users`).then((response) => {
        this.users = response.data.data;
      });
    },
    onClose() {
      this.$emit("update:isOpen", false);
    },
  },
};
</script>
