<template>
  <v-dialog :value="isOpen" width="350" persistent>
    <v-card>
      <v-card-text>
        <div class="px-3" style="display: flex; flex-direction: column; align-items: center">
          <br />
          <br />
          <img
            src="@/assets/images/check.svg"
            alt="Tu empresa fue creada con éxito"
            class="img-fluid"
            width="56"
            height="56"
          />
          <br />
          <div class="text-center px-2">
            <h3
              class="primary--text mb-3"
              style="margin-bottom: 0px !important; color: #101820 !important"
            >
              ¡Empresa creada!
            </h3>
            <!-- <br />
            <v-btn
              :disabled="loading"
              @click="onAutoLogin"
              color="primary"
              depressed
              style="border-radius: 5px !important; font-size: 12px; padding-inline: 10px; height: 32px;"
              >IR A MI EMPRESA</v-btn
            > -->
            <br /><br />
            <v-btn
              @click="onClose"
              text
              :disabled="disabledCreateButton"
              :loading="disabledCreateButton"
              style="
                border-radius: 5px !important;
                font-size: 12px;
                padding-inline: 10px;
                height: 32px;
              "
              >CERRAR</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    domain: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    disabledCreateButton: true,
  }),
  watch: {
    domain() {
      setTimeout(() => {
        this.disabledCreateButton = false;
      }, 3000);
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    async onAutoLogin() {
      await this.$store.dispatch("ON_AUTO_LOGIN", this.domain);
      this.onClose();
    },
    onClose() {
      this.$emit("update:isOpen", false);
      this.$emit("update:domain", "");
      setTimeout(() => {
        this.disabledCreateButton = true;
      }, 4000);
    },
  },
};
</script>
