<template>
  <v-main class="bg-body-color main">
    <div style="justify-content: flex-start;" class="wrapper__auth-new">
      <div class="auth__box">
        <span class="title__auth">Verifica tu cuenta</span>
        <p class="auth__box-p" style="margin-bottom: 16px !important;" v-if="user.hasEmail">Enviamos un código al correo {{ user.email }}</p>
        <p class="auth__box-p" style="margin-bottom: 16px !important;" v-if="!user.hasEmail">Enviamos un código al número {{ user.phone }}</p>
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formChangePassword">
              <form @submit.prevent="onSubmit" autocomplete="off" class="form-password">
                <ValidationProvider  name="código de verificación" rules="required|digits:6"
                  v-slot="{ errors }" vid="code">
                  <v-text-field label="Código de verificación" v-model="form.code" :error-messages="errors" class="profile"
                    v-mask="'######'" :counter="6"></v-text-field>
                </ValidationProvider>
                <div>
                  <v-btn
                    depressed
                    type="submit"
                    block
                    rounded :disabled="invalid || loading" :loading="loading"
                    class="v-btn-pad-x-large2" style="border-radius: 5px !important; background-color: #D92D20; color: white; margin-top: 16px;"
                    ><span class="text-button-new">
                      Continuar
                    </span></v-btn>
                </div>
              </form>
            </ValidationObserver>
      </div>

      <div>
        <span style="padding-top: 4px; font-size: 14px !important; padding-bottom: 16px;">
        ¿No recibiste el código? <span
        :disabled="loading" @click="onResendCode" style="text-decoration: underline; color: #0375f8;">
          <small style="font-size: 14px !important; cursor: pointer;">Reenviar</small>
      </span>
        </span>
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL_WEB,
      form: {
        code: '',
      },
      showVerifyModal: false,
    };
  },
  mounted() {

  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      countries: (state) => state.countries,
      user: (state) => state.user,
    }),
  },
  methods: {
    onSubmit() {},
    onResendCode() {},
  },
};
</script>
