<template>
  <v-dialog :value="isOpen" width="390" @input="onChangeStatusModel">
    <ValidationObserver v-slot="{ invalid }" tag="div" ref="formCreateBusiness">
      <form @submit.prevent="onSubmit" autocomplete="off">
        <v-card>
          <v-card-title style="padding: 12px 24px;border-bottom: 1px solid var(--grey-grey-200, #EEE);
        font-size: 16px !important; font-weight: 700;">
          <div
            style="font-size: 14px !important; font-weight: 700;display: flex;align-items: center;justify-content: space-between;width: 100%;">
            <span style="padding: 4px 0px;">Editar empresa</span>
            <div style="display: flex;padding: 8px;justify-content: flex-end;align-items: center;" @click="onClose">
              <img src="@/assets/images/icon-close.svg" width="16"  />
            </div>
          </div>
        </v-card-title>

          <div style="display: flex; flex-direction: column;align-items: center;gap: 20px;padding: 16px 24px 4px 24px;">
            <v-card-text style="display: flex;flex-direction: column;gap: 24px; padding: 0px !important;">
            <div style="display: flex; flex-direction: column;align-items: center;">
              <div class="business__logo">
              <div class="business__logo-wrapper">
                <img
                  :src="preview"
                  alt="Logo"
                  class="img-fluid cursor-pointer"
                  style="width: 100%; height: 180px; object-fit: contain"
                  @click="onSelectFile"
                  />
              </div>
              <input type="file" class="hidden" ref="inputFile" @change="onChange" />
              <v-btn icon  @click="onSelectFile">
                <i class="fa fa-camera"></i>
              </v-btn>
            </div>
            </div>
            <ValidationProvider
              name="nombre"
              rules="required|max:250"
              v-slot="{ errors }"
              vid="name"
            >
              <v-text-field
                style="margin-bottom: 0px !important;"
                label="Nombre corto *"
                v-model="form.name"
                :error-messages="errors"
                hint="Ejemplo: Geor, Elishop, CNC"
                class="modal-create"
              ></v-text-field>
            </ValidationProvider>

            <!-- <ValidationProvider
              name="categoría"
              rules="required|numeric"
              v-slot="{ errors }"
              vid="business_sector_id"
            >
              <v-select
                style="margin-bottom: 0px !important; font-size: 14px !important;"
                label="Categoría *"
                :error-messages="errors"
                :items="sectors"
                v-model="form.business_sector_id"
                item-text="name"
                item-value="id"
                class="modal-create"
              ></v-select>
            </ValidationProvider> -->
            <!-- <ValidationProvider
              name="sitio web"
              rules="max:200|url"
              v-slot="{ errors }"
              vid="website"
            >
              <v-text-field
                style="margin-bottom: 0px !important;"
                label="Página web (Opcional)"
                v-model="form.website"
                :error-messages="errors"
                class="modal-create"
              ></v-text-field>
            </ValidationProvider> -->
          </v-card-text>
          <div style="padding: 0px !important; display: flex; justify-content: right; width: 100%;">
            <v-card-actions style="padding: 0px !important">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              type="submit"
              :disabled="invalid || loading"
              :loading="loading"
              block
              class="v-btn-pad-x-large btn-new" style="padding-inline: 10px !important; height: 32px  !important; border-radius: 5px !important;"
              ><span class="text-button-new">Editar empresa</span></v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
          </div>
          </div>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
// import UploadLogo from "@/components/partials/UploadLogo.vue";

export default {
  components: {
    // UploadLogo,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    project: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: "",
        logo: "",
        website: "",
        business_sector_id: "",
      },
      sectors: [],
      preview: "",
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  watch: {
    isOpen(value) {
      if (this.project) {
        this.form.name = this.project.name;
        this.preview = this.project.logo || "/images/image-placeholer.png";
        this.form.website = this.project.website;
        this.form.business_sector_id = this.project.business_sector_id;
        this.form.logo = "";
      }
      if (value && this.sectors.length < 1) {
        this.onFetchCategories();
      }
    },
  },
  methods: {
    onSelectFile() {
      this.$refs.inputFile.click();
    },
    onFetchCategories() {
      this.$axios.get("projects/tables").then((response) => {
        this.sectors = response.data.sectors;
      });
    },
    onSubmit() {
      this.$axios.put(`projects/${this.project.id}/update`, this.form)
        .then((response) => {
          const { data } = response.data;
          this.$emit("onEditProject", data);
          this.onClose();
        }).catch((error) => this.showErrors(error, "formCreateBusiness"));
    },
    onClose() {
      this.$emit("update:isOpen", false);
    },
    onChangeStatusModel(value) {
      if (!value) {
        this.onClose();
      }
    },
    onChange(event) {
      const reader = new FileReader();
      const file = event.target.files[0];

      reader.onloadend = () => {
        this.form.logo = reader.result;
        this.preview = reader.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      } else {
        this.preview = this.project.logo;
      }
    },
  },
};
</script>
