<template>
  <v-dialog :value="isOpen" width="350" persistent>
    <v-card>
      <v-card-text style="
            width: var(--Modal-Medium);
            height: 550px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <br />
          <br />
          <img
            src="/images/bot.gif"
            alt=""
            style="height: 80px; width: 80px;"
          />
          <br />
          <br />
          <div class="text-center px-2" style="display: flex; flex-direction: column; align-items: center;">
            <v-progress-linear
              indeterminate
              color="primary"
              style="height: 7px; border-radius: 28px; width: 70%;"
            ></v-progress-linear>
            <span class="mb-3" style="padding-top: 16px;font-size: 14px;font-weight: 400; font-size: 14px !important; font-weight: 500; color: rgb(16, 24, 32);" >
              Nuestros robots están creando la empresa; lo tendrán listo en unos segundos.
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
