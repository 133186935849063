<template>
  <v-dialog persistent max-width="450" :value="isOpen">
    <v-card>
      <v-card-title style="padding: 12px 24px;border-bottom: 1px solid var(--grey-grey-200, #EEE);
        font-size: 16px !important; font-weight: 700;">
          <div
            style="font-size: 14px !important; font-weight: 700;display: flex;align-items: center;justify-content: space-between;width: 100%;">
            <span style="padding: 4px 0px;">Agregar correo</span>
            <div style="display: flex;padding: 8px;justify-content: flex-end;align-items: center; cursor: pointer;" @click="onClose">
              <img src="@/assets/images/icon-close.svg" width="16" />
            </div>
          </div>
        </v-card-title>
      <v-card-text style="padding-inline: 24px !important; padding-bottom: 0px; padding-top: 20px;">
        <span style="font-size: 12px; letter-spacing: 0;">
          Aún no tienes un correo electrónico asociado a tu cuenta, si deseas
          puedes agregarlo ahora.
        </span>
        <ValidationObserver
          v-slot="{ invalid }"
          tag="div"
          ref="formChangeEmail"
        >
          <form @submit.prevent="onSubmit" autocomplete="off" class="pt-5">
            <ValidationProvider
              name="email"
              rules="required|email|max:190"
              v-slot="{ errors }"
              vid="email"
            >
              <v-text-field
                label="Correo electrónico"
                v-model="form.email"
                :error-messages="errors"
                :counter="190"
                :disabled="hasPreviousChange"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-if="hasPreviousChange"
              name="código"
              rules="required|length:6"
              v-slot="{ errors }"
              vid="code"
            >
              <v-text-field
                label="Código de verificación"
                v-model="form.code"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
              <div class="mt-5"  style="display: flex;padding: 0px 0px 4px 0px;justify-content: flex-end;gap:10px;">

              <v-btn
                style="height: 32px; border-radius: 8px;border: 1px solid var(--Secondary-Outline-border, #E0E0E0); background-color: transparent; border-radius: 5px;
                margin-right:0px !important;padding-inline: 10px !important;"
                class="me-5"
                depressed
                @click="onClose"
                :disabled="loading"
                ><span class="text-button-new">Omitir</span></v-btn
              >
              <v-btn
                style="height: 32px; border-radius: 8px;margin-right:0px !important;padding-inline: 10px !important; border-radius: 5px;" color="primary"
                type="submit"
                :loading="loading"
                :disabled="invalid || loading"
                class="me-5"
                depressed
                ><span class="text-button-new">Agregar</span></v-btn
              >
            </div>
          </form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: "",
        code: "",
      },
      hasPreviousChange: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: (state) => state.user,
    }),
  },
  methods: {
    onSubmit() {
      const payload = {
        email: this.form.email,
      };

      if (this.hasPreviousChange) {
        payload.code = this.form.code;
      }

      this.$axios
        .post("users/change-email", payload)
        .then((response) => {
          const { data } = response;
          const { action } = data;
          if (action === "CODE_SENT") {
            this.hasPreviousChange = true;
          }
          if (action === "EMAIL_CHANGED") {
            const { user } = this;
            user.hasEmail = true;
            user.email = this.form.email;
            this.$auth.saveUser(user);
            this.$emit("updateUserEmail");
            this.$store.commit("REFRESH_USER_DATA");
            this.onClose();
          }
          this.$toast.success(data.message);
        })
        .catch((error) => {
          const { data } = error.response;
          if (data.action === "MANY_TRIES") {
            this.onClose();
          }
          this.showErrors(error, "formChangeEmail");
        });
    },
    onClose() {
      localStorage.setItem("closeModalSuggeted", "on");
      this.$emit("update:isOpen", false);
    },
  },
};
</script>
