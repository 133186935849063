<template>
  <v-container>
    <div class="v-overlay__scrim" style="" v-show="showBackdropV" @click="disabledBackdrop"></div>
    <div class="align-center dashboard__title" v-if="projectsAux.length > 0 && !loading">
      <p
        class="me-auto dashboard__title-text"
        style="font-size: 16px; color: #101820; padding-left: 0px"
      >
        <span class="font-weight-bold">Hola {{ getFirstName(user.full_name) }} </span> 😊, ¿dónde
        trabajaremos hoy?
      </p>
      <template v-if="projectsAux.length > 0">
        <div class="header-buttons">
          <div
            class="content-search-input"
            :class="{ focusedSearch: isFocused, 'content-search-input-desactive': !isFocused }"
            :style="{
              'justify-content': this.stringToSearch === '' ? 'space-between' : 'space-between',
            }"
          >
            <div style="display: flex; gap: 8px; width: 100%">
              <div
                style="
                  display: flex;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  min-width: 18px;
                "
              >
                <img
                  src="@/assets/images/search.svg"
                  alt="category-type"
                  class="img-fluid"
                  style="width: 18px; height: 18px; margin: auto !important"
                />
              </div>
              <div :class="{ inputSearch: !isFocused, inputSearchActive: isFocused }">
                <input
                  v-model="stringToSearch"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  @change="updatePreview"
                  placeholder="Buscar"
                  ref="searchInput"
                  class="inputSearch"
                  style="width: 100px"
                  type="search"
                  :style="{ outline: isFocused ? 'none' : '' }"
                />
              </div>
            </div>
            <template v-if="stringToSearch === ''">
              <div
                style="display: flex; gap: 2px"
                class="ctrl-k"
                :class="{ searchActive: isFocused }"
              >
                <span
                  style="
                    padding: 1px 4px !important;
                    background: #f1f2f3;
                    font-size: 10px;
                    border-radius: 2px;
                  "
                >
                  CTRL
                </span>
                <span
                  style="
                    padding: 1px 4px !important;
                    background: #f1f2f3;
                    font-size: 10px;
                    border-radius: 2px;
                  "
                >
                  K
                </span>
              </div>
            </template>
            <template v-if="stringToSearch !== ''">
              <img
                src="@/assets/images/close-fill.svg"
                alt="close-fill"
                style="cursor: pointer"
                @click="cleanInput"
              />
            </template>
          </div>
          <div class="header-buttons-create" :class="{ searchActive: isFocused }">
            <v-btn
              small
              depressed
              :outlined="viewMode === 'list'"
              class="v-btn-box padding-button button-hover"
              :class="{ 'icon-selected': viewMode == 'list', 'icon-header': viewMode == 'grid' }"
              @click="onSetViewMode('list')"
              style="min-width: 30px !important; border: none"
            >
              <img
                src="@/assets/images/list-type.svg"
                alt="category-type"
                class="img-fluid"
                width="18"
              />
            </v-btn>
            <v-btn
              small
              depressed
              :outlined="viewMode === 'grid'"
              class="v-btn-box padding-button button-hover"
              :class="{ 'icon-selected': viewMode === 'grid', 'icon-header': viewMode == 'list' }"
              @click="onSetViewMode('grid')"
              style="border: none"
            >
              <img
                src="@/assets/images/category-type.svg"
                alt="category-type"
                class="img-fluid"
                width="18"
              />
            </v-btn>
            <v-btn
              color="primary"
              depressed
              dark
              class="v-btn-box button"
              @click="isOpenModalCreateBusiness = true"
              style="height: 40px; border-radius: 10px; padding: 0 10px !important"
            >
              <span class="text-button-new">Crear empresa</span>
            </v-btn>
          </div>
        </div>
      </template>
    </div>
    <ProjectsNone v-if="projectsAux.length < 1 && !loading" @success="getCompany"></ProjectsNone>
    <template v-else>
      <div v-if="viewMode === 'grid'" style="" class="grid-company">
        <v-card
          class="projects__mode-grid"
          style="
            height: 172px;
            width: 150px;
            box-shadow: none;
            border: 1px solid #eeeeee;
            padding: 16px;
            background-color: white;
          "
          :key="`col${project.id}`"
          v-for="project in projects"
          @click.native="onAutoLogin(project.domain)"
        >
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            absolute
            bottom
            :class="{ 'm-left': project.is_owner }"
            style="background-color: transparent !important; width: 100%; padding: 0px !important"
          >
            <div style="display: flex; align-items: center; width: 100%">
              <p
                style="
                  margin-bottom: 0px !important;
                  width: 100%;
                  text-align: left;
                  color: #48626f !important;
                  font-size: 10px !important;
                "
              >
                desde el {{ formatDate(project.created_at) }}
              </p>
              <v-menu offset-y v-if="project.is_admin" style="z-index: 9999">
                <template v-slot:activator="{ on, attrs }">
                  <div>
                    <!-- <v-btn style="background-color: transparent !important; border: none !important;"
                    class="v-btn-project"
                    depressed
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon  size="18">fa-ellipsis-v</v-icon>
                  </v-btn> -->
                    <div style="min-width: 16px; height: 16px">
                      <img
                        src="@/assets/images/menu-dots-v.svg"
                        @click="showBackdrop"
                        v-bind="attrs"
                        v-on="on"
                        alt="menu-dots"
                        class="icon-hover-grid"
                        width="16"
                      />
                    </div>
                  </div>
                </template>
                <v-list dense class="v-list-dashboard">
                  <v-list-item @click="onAutoLogin(project.domain)" class="v-list-item-dashboard">
                    <v-list-item-title style="font-size: 14px">Abrir</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onEdit(project)" class="v-list-item-dashboard">
                    <v-list-item-title style="font-size: 14px">Editar</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item @click="onShowUsers(project)" class="v-list-item-dashboard">
                      <v-list-item-title style="font-size: 14px;">Miembros</v-list-item-title>
                    </v-list-item> -->
                  <v-list-item
                    @click="onDelete(project)"
                    style="border-top: 2px solid #eeeeee; color: #d92d20 !important"
                    class="v-list-item-dashboard"
                    v-if="project.is_owner"
                  >
                    <v-list-item-title style="font-size: 14px">Eliminar empresa</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-app-bar>
          <div style="display: flex; width: 100%; justify-content: flex-start">
            <img
              :src="project.logo"
              :alt="project.name"
              class="img-fluid cursor-pointer"
              height="56"
              width="56"
              style="
                border-radius: 100px;
                object-fit: cover;
                background-size: cover;
                height: 56px;
                width: 56px;
              "
            />
          </div>
          <div class="text-box">
            <div class="text-box__label">
              {{ project.name }}
            </div>
          </div>
        </v-card>
      </div>
      <v-simple-table v-else class="mode-desktop">
        <template v-slot:default>
          <thead>
            <tr style="background-color: transparent">
              <th
                style="
                  background-color: transparent;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  border-bottom: none !important;
                  color: #48626f;
                  font-weight: 400;
                  cursor: pointer;
                "
                @click="toggleSortOrder"
              >
                Nombre
                <img
                  src="@/assets/images/arrow-down.svg"
                  alt="menu-dots"
                  width="16"
                  v-show="ascending"
                />
                <img
                  src="@/assets/images/arrow-up.svg"
                  alt="menu-dots"
                  width="16"
                  v-show="!ascending"
                />
              </th>
              <th
                style="
                  background-color: transparent;
                  font-size: 12px;
                  border-bottom: none !important;
                  color: #48626f;
                  font-weight: 400;
                  position: relative;
                  cursor: pointer;
                "
                @click="toggleSortOrderDate"
              >
                Creación
                <img
                  src="@/assets/images/arrow-down.svg"
                  alt="menu-dots"
                  width="16"
                  v-show="ascendingDate"
                  style="position: absolute"
                />
                <img
                  src="@/assets/images/arrow-up.svg"
                  alt="menu-dots"
                  width="16"
                  v-show="!ascendingDate"
                  style="position: absolute"
                />
              </th>
              <th style="background-color: transparent; border-bottom: none !important"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(project, ind) in sortedProjects"
              :key="`tr${ind}`"
              class="hover-icon"
              @click="onAutoLogin(project.domain)"
              style="border-radius: 20px; cursor: pointer"
            >
              <td
                style="
                  padding-block: 9px !important;
                  border-bottom: none !important;
                  border-radius: 5px 0 0 5px;
                "
              >
                <div class="div-horizontal-centered">
                  <div style="display: flex; align-items: center; font-size: 14px; color: #101820">
                    <div
                      class="text-center"
                      style="
                        width: 70px;
                        display: flex;
                        align-items: center;
                        justify-content: start;
                      "
                    >
                      <img
                        :src="project.logo"
                        :alt="project.name"
                        class="img-fluid"
                        style="
                          max-witdh: 70px;
                          max-height: 32px;
                          width: 32px;
                          height: 32px;
                          border-radius: 100px;
                          object-fit: cover;
                          margin-block: 3px;
                        "
                      />
                    </div>
                    {{ project.name }}
                  </div>
                </div>
              </td>
              <td
                style="font-size: 14px; color: #48626f !important; border-bottom: none !important"
              >
                desde el {{ formatDate(project.created_at) }}
              </td>
              <td
                class="text-right icon-text"
                style="border-bottom: none !important; border-radius: 0 5px 5px 0"
              >
                <v-menu v-if="project.is_admin" offset-y style="z-index: 9999">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      style="
                        min-width: 18px;
                        height: 18px;
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <img
                        src="@/assets/images/menu-dots.svg"
                        @click="showBackdrop"
                        v-bind="attrs"
                        v-on="on"
                        alt="menu-dots"
                        class="img-none"
                        width="18"
                      />
                    </div>
                    <!-- <img src="@/assets/images/menu-dots.svg" v-bind="attrs" v-on="on" alt="menu-dots" class="img-fluid" width="24"/> -->
                    <!-- <img src="@/assets/images/menu-dots.svg" v-show="on" v-bind="attrs"  v-on="on" alt="menu-dots" class="img-fluid" width="24"/> -->
                  </template>
                  <v-list dense class="v-list-dashboard">
                    <v-list-item @click="onAutoLogin(project.domain)" class="v-list-item-dashboard">
                      <v-list-item-title style="font-size: 14px">Abrir</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onEdit(project)" class="v-list-item-dashboard">
                      <v-list-item-title style="font-size: 14px">Editar</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item @click="onShowUsers(project)" class="v-list-item-dashboard">
                      <v-list-item-title style="font-size: 14px">Miembros</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item
                      @click="onDelete(project)"
                      style="border-top: 2px solid #eeeeee; color: #d92d20 !important"
                      class="v-list-item-dashboard"
                      v-if="project.is_owner"
                    >
                      <v-list-item-title style="font-size: 14px"
                        >Eliminar empresa</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <template>
        <div class="mode-mobile" v-if="viewMode !== 'grid'">
          <div
            v-for="(project, ind) in sortedProjects"
            :key="`tr${ind}`"
            @click="onAutoLogin(project.domain)"
          >
            <div
              style="display: flex; justify-content: space-around; align-items: center; width: 100%"
            >
              <div style="display: flex; align-items: center; gap: 10px; flex: 1">
                <img
                  :src="project.logo"
                  :alt="project.name"
                  class="img-fluid"
                  style="
                    max-witdh: 40px;
                    max-height: 40px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                    object-fit: cover;
                  "
                />
                <span style="font-size: 14px; color: #101820; font-weight: 500; display: grid">
                  <span>{{ project.name }}</span>
                  <span
                    style="
                      font-size: 12px;
                      color: #48626f !important;
                      border-bottom: none !important;
                    "
                    >desde el {{ formatDate(project.created_at) }}</span
                  >
                </span>
              </div>
              <v-menu v-if="project.is_admin" offset-y style="z-index: 9999">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    style="
                      min-width: 18px;
                      height: 18px;
                      display: flex;
                      justify-content: space-around;
                      max-width: 18px;
                    "
                  >
                    <img
                      src="@/assets/images/menu-dots.svg"
                      @click="showBackdrop"
                      v-bind="attrs"
                      v-on="on"
                      alt="menu-dots"
                      class="img-none"
                      width="18"
                    />
                  </div>
                  <!-- <img src="@/assets/images/menu-dots.svg" v-bind="attrs" v-on="on" alt="menu-dots" class="img-fluid" width="24"/> -->
                  <!-- <img src="@/assets/images/menu-dots.svg" v-show="on" v-bind="attrs"  v-on="on" alt="menu-dots" class="img-fluid" width="24"/> -->
                </template>
                <v-list dense class="v-list-dashboard">
                  <v-list-item @click="onAutoLogin(project.domain)" class="v-list-item-dashboard">
                    <v-list-item-title style="font-size: 14px">Abrir</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onEdit(project)" class="v-list-item-dashboard">
                    <v-list-item-title style="font-size: 14px">Editar</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item @click="onShowUsers(project)" class="v-list-item-dashboard">
                      <v-list-item-title style="font-size: 14px;">Miembros</v-list-item-title>
                    </v-list-item> -->
                  <v-list-item
                    @click="onDelete(project)"
                    style="border-top: 2px solid #eeeeee; color: #d92d20 !important"
                    class="v-list-item-dashboard"
                    v-if="project.is_owner"
                  >
                    <v-list-item-title style="font-size: 14px">Eliminar empresa</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </template>
    </template>
    <div
      v-if="projectsAux.length === 0 && loading"
      style="display: flex; justify-content: center; align-items: center; height: 85vh"
    >
      <div class="loader"></div>
    </div>
    <div v-if="!hasResult && searching" style="display: flex; justify-content: center">
      <p>
        No hay resultados para <span style="font-weight: 700">"{{ stringToSearch }}"</span>
      </p>
    </div>
    <ModalCreateBusiness
      @onAddProject="onAddProject"
      :is-open.sync="isOpenModalCreateBusiness"
    ></ModalCreateBusiness>
    <ModalSuggetAddEmail :is-open.sync="openModalSuggetAddEmail"></ModalSuggetAddEmail>
    <ModalEditBusiness
      :isOpen.sync="openModalEdit"
      :project="project"
      @onEditProject="onUpdateProject"
    ></ModalEditBusiness>
    <ModalDelete
      :show.sync="openModalDelete"
      :title="titleModalDelete"
      :message="messageDelete"
      @onDeleteProject="onDestroy"
    ></ModalDelete>
    <ModalWarning :show.sync="openModalWarning" title="ATENCIÓN" :message="messageWarning">
      <template slot="btns">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn depressed color="primary" @click="onChangePassword"
              >cambiar mi contraseña ahora!!!</v-btn
            >
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn depressed @click="onCancel">Más tarde lo hago</v-btn>
          </v-col>
        </v-row>
      </template>
    </ModalWarning>
    <!-- <ModalProjectUsers
      :isOpen.sync="openModalProjectUsers"
      :project="project"
    ></ModalProjectUsers> -->
  </v-container>
</template>
<style>
/* .v-overlay__scrim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
/* Asegúrate de que esté por encima del resto del contenido */
/* z-index: 999;  */

.loader {
  width: 24px;
  height: 24px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid lightgrey;
  border-left-color: #e7eaee;
  animation: _360degAnimation 900ms linear infinite;
}

@keyframes _360degAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<script>
import { mapState } from "vuex";
import moment from "moment";
import ModalSuggetAddEmail from "@/components/profile/ModalSuggestAddEmail.vue";
import ModalWarning from "@/components/partials/ModalWarning.vue";
import ModalDelete from "@/components/partials/ModalDelete.vue";
import ProjectsNone from "./ProjectsNone.vue";
import ModalCreateBusiness from "./ModalCreateBusiness.vue";
import ModalEditBusiness from "./ModalEditBusiness.vue";
// import ModalProjectUsers from "../../components/projects/Users.vue";

export default {
  components: {
    ProjectsNone,
    ModalCreateBusiness,
    ModalSuggetAddEmail,
    ModalEditBusiness,
    ModalDelete,
    ModalWarning,
    // ModalProjectUsers,
  },
  data() {
    return {
      stringToSearch: "",
      searching: false,
      // openModalProjectUsers: false,
      openModalSuggetAddEmail: false,
      baseUrl: process.env.VUE_APP_URL_WEB,
      projects: [],
      projectsAux: [],
      isOpenModalCreateBusiness: false,
      viewMode: "grid",
      openModalEdit: false,
      project: null,
      openModalDelete: false,
      titleModalDelete: "¿Eliminar empresa?",
      messageDelete: "",
      openModalWarning: false,
      projectsOrder: [],
      ascending: true,
      ascendingDate: false,
      orderType: "nombre",
      loading: true,
      isFocused: false,
      showBackdropV: false,
      hasResult: false,
      messageWarning:
        "Se ha detectado que la contraseña que estás usando es débil, se recomienda cambiar de contraseña lo más pronto posible.",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    sortedProjects() {
      // Creamos una copia ordenada del array original
      const sortedArray = this.projects.slice().sort((a, b) => {
        let comparison;
        if (this.orderType === "nombre") {
          comparison = a.name.localeCompare(b.name);
        } else {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          comparison = dateA - dateB;
        }
        if (!this.ascendingDate && this.orderType === "fecha") {
          comparison *= -1;
        }
        if (!this.ascending && this.orderType === "nombre") {
          comparison *= -1;
        }
        return comparison;
      });
      return sortedArray;
    },
  },
  mounted() {
    this.getCompany();
    document.addEventListener("keydown", (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        this.$refs.searchInput.focus();
      }
    });
    this.viewMode = localStorage.getItem("viewModeProject") || "grid";
    const closeModalSuggeted = localStorage.getItem("closeModalSuggeted");
    if (!closeModalSuggeted) {
      if (!this.user.hasEmail) {
        this.openModalSuggetAddEmail = true;
      }
    }

    this.openModalWarning = this.user.changePassword;
  },
  methods: {
    showBackdrop() {
      this.showBackdropV = true;
    },
    disabledBackdrop() {
      this.showBackdropV = false;
    },
    getCompany() {
      this.$axios.get("projects/only/facturacion").then((response) => {
        this.projects = response.data.data;
        this.projectsAux = response.data.data;
        this.projectsOrder = response.data.data;
        this.loading = false;
      });
    },
    // onShowUsers(project) {
    //   this.project = { ...project };
    //   this.openModalProjectUsers = true;
    // },
    formatDate(dateString) {
      const date = moment(dateString).locale("es");
      return date.format("D MMM YYYY");
    },
    getFirstName(fullName) {
      // Dividir la cadena por espacios y devolver la primera palabra
      if (fullName) {
        return fullName.split(" ")[0];
      }
      return "";
    },
    onChangePassword() {
      this.onCancel();
      this.$store.dispatch("SET_OPEN_MODAL_CHANGE_PASSWORD", true);
    },
    onCancel() {
      const user = { ...this.user };
      user.changePassword = false;
      this.$auth.saveUser(user);
      this.$store.dispatch("REFRESH_USER_DATA");
      this.openModalWarning = false;
    },
    onDestroy() {
      this.$axios.delete(`projects/${this.project.id}/delete`).then((response) => {
        this.openModalDelete = false;
        this.projects = this.projects.filter((p) => p.id !== this.project.id);
        this.project = null;
        this.getCompany();
        this.$toast.success(response.data.message);
      });
    },
    onDelete(project) {
      this.messageDelete = `¿Seguro que quieres eliminar la empresa ${project.name}?, esto no se puede deshacer?`;
      this.openModalDelete = true;
      this.project = { ...project };
    },
    onUpdateProject(project) {
      this.projects = this.projects.map((p) => {
        if (p.id === project.id) {
          return project;
        }
        return p;
      });
    },
    onEdit(project) {
      this.project = { ...project };
      this.openModalEdit = true;
    },
    onSetViewMode(mode) {
      localStorage.setItem("viewModeProject", mode);
      this.viewMode = mode;
    },
    onAutoLogin(domain) {
      this.$store.dispatch("ON_AUTO_LOGIN", domain);
    },
    onAddProject() {
      this.getCompany();
    },
    toggleSortOrder() {
      this.ascending = !this.ascending;
      this.orderType = "nombre";
    },
    toggleSortOrderDate() {
      this.ascendingDate = !this.ascendingDate;
      this.orderType = "fecha";
    },
    updatePreview(event) {
      this.$emit("update", event.target.value);
    },
    focusInput(event) {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      setTimeout(() => {
        this.isFocused = false;
      }, 100);
    },
    cleanInput() {
      this.stringToSearch = "";
    },
  },
  watch: {
    stringToSearch() {
      if (this.stringToSearch.length === 0) {
        this.projects = this.projectsAux;
        this.searching = false;
      } else {
        this.hasResult = true;
        this.searching = true;
        const searchString = this.stringToSearch.toLowerCase();
        this.projects = this.projectsAux.filter((element) => {
          const nameAux = element.name.toLowerCase();
          return nameAux.includes(searchString);
        });
      }

      // Verificar si no se encontraron resultados
      if (this.projects.length === 0) {
        this.hasResult = false;
        // Aquí puedes agregar cualquier otra lógica que necesites cuando no hay resultados
      }
    },
  },
};
</script>
