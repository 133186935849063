<template>
    <div class="help-container">
        <div class="help-content" >
            <div class="help-content__title">
                <span><span class="say">Hola {{ getFirstName(user.full_name) }} 😊,</span> ¿Cómo podemos ayudarte? </span>
            </div>
            <div class="help-content__body" >
                <div class="help-whatsapp" >
                    <img src="@/assets/images/whatsapp.svg"  class="img-fluid" />
                </div>
                <div class="help-datas">
                    <div class="help-datas__title">
                        <span>
                            WhatsApp
                        </span>
                    </div>
                    <div class="help-datas__body">
                        <span>
                            Escríbenos de lunes a sábado de 8:00 a 18:00 horas para atender cualquier consulta.
                        </span>
                    </div>
                    <div class="help-datas__button">
                        <v-btn  style="padding:0 10px!important; font-size: 12px !important;" @click="sendWhatsApp()" class="help-button"><span>Hablar con soporte</span></v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Help",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    sendWhatsApp() {
      window.open('https://api.whatsapp.com/send?phone=51985071701&text=Hola,%20estoy%20buscando%20asistencia%20con%20Geo%20Web.%20%C2%BFPodr%C3%ADan%20ayudarme?%20%F0%9F%A4%9D', '_blank');
    },
    getFirstName(fullName) {
      // Dividir la cadena por espacios y devolver la primera palabra
      if (fullName) {
        return fullName.split(" ")[0];
      }
      return '';
    },
  },
};
</script>
