<template>
  <v-dialog v-model="show" max-width="400" persistent>
    <v-card>
      <div class="text-center pt-10">
        <img
          src="@/assets/images/warning.png"
          alt="Icono adventencia"
          class="img-fluid"
          width="80"
        />
      </div>
      <div class="text-center mt-5">
        <strong class="text-h6">{{ title }}</strong>
        <v-card-text>{{ message }}</v-card-text>
        <br />
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="btns"></slot>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    onCancel() {
      this.$emit("update:show", false);
    },
    onAccept() {
      this.$emit("onDeleteProject");
    },
  },
};
</script>
