<template>
  <v-dialog :value="isOpen" width="390" @input="onChangeStatusModel">
    <ValidationObserver v-slot="{ invalid }" tag="div" ref="formCreateBusiness">
      <form @submit.prevent="onSubmit" autocomplete="off">
        <v-card>
          <v-card-title
            style="
              padding: 12px 24px;
              border-bottom: 1px solid var(--grey-grey-200, #eee);
              font-size: 16px !important;
              font-weight: 700;
            "
          >
            <div
              style="
                font-size: 14px !important;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
              "
            >
              <span style="padding: 4px 0px">Crear empresa</span>
              <div
                style="display: flex; padding: 8px; justify-content: flex-end; align-items: center"
                @click="onClose"
                class="cursor-pointer"
              >
                <img src="@/assets/images/icon-close.svg" width="16" />
              </div>
            </div>
          </v-card-title>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 20px;
              padding: 16px 24px 4px 24px;
            "
          >
            <v-card-text
              style="display: flex; flex-direction: column; gap: 24px; padding: 0px !important"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="logo"
                style="display: flex; align-items: center; justify-content: center"
              >
                <UploadLogo @onSetLogo="onSetLogo"></UploadLogo>
                <small class="is-invalid mt-3">{{ errors[0] }}</small>
              </ValidationProvider>
              <ValidationProvider
                name="nombre"
                rules="required|max:150"
                v-slot="{ errors }"
                vid="business_name"
              >
                <v-text-field
                  style="margin-bottom: 0px !important"
                  label="Nombre corto *"
                  v-model="form.business_name"
                  :error-messages="errors"
                  hint="Ejemplo: Geor, Elishop, CNC"
                  autofocus
                  class="modal-create"
                ></v-text-field>
              </ValidationProvider>
              <ModalLoadingCreateBusiness :isOpen.sync="showModalLoading">
              </ModalLoadingCreateBusiness>
              <ModalBusinessCreated
                :isOpen.sync="showModalCreated"
                :domain.sync="domain"
              ></ModalBusinessCreated>
            </v-card-text>
            <div
              style="padding: 0px !important; display: flex; justify-content: right; width: 100%"
            >
              <v-card-actions style="padding: 0px !important">
                <v-btn
                  color="primary"
                  depressed
                  type="submit"
                  :disabled="invalid || loading"
                  :loading="loading"
                  block
                  class="v-btn-pad-x-large btn-new"
                  style="
                    padding-inline: 10px !important;
                    height: 32px !important;
                    border-radius: 5px !important;
                    font-weight: 300 !important;
                  "
                  ><span class="text-button-new">Crear empresa</span></v-btn
                >
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import UploadLogo from "@/components/partials/UploadLogo.vue";
import { mapState } from "vuex";
import ModalLoadingCreateBusiness from "./ModalLoadingCreateBusiness.vue";
import ModalBusinessCreated from "./ModalBusinessCreated.vue";

export default {
  components: {
    UploadLogo,
    ModalLoadingCreateBusiness,
    ModalBusinessCreated,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      form: {
        logo: null,
      },
      showModalLoading: false,
      showModalCreated: false,
      sectors: [],
      domain: "",
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: (state) => state.user,
    }),
  },
  watch: {
    isOpen(value) {
      if (value && this.sectors.length < 1) {
        this.onFetchCategories();
      }
    },
  },
  methods: {
    initForm() {
      this.form.logo = null;
      this.form.business_name = "";
      this.business_sector_id = "";
      this.form.website = "";
    },
    onFetchCategories() {
      this.$axios.get("projects/tables").then((response) => {
        this.sectors = response.data.sectors;
      });
    },
    onSubmit() {
      this.onClose();
      this.showModalLoading = true;
      this.$axios
        .post("projects/store", this.form)
        .then((response) => {
          this.showModalCreated = true;
          const { data } = response.data;
          this.domain = data.client.domain;
          this.$emit("onAddProject");
          this.initForm();
          this.onClose();
        })
        .finally(() => {
          this.showModalLoading = false;
        })
        .catch((error) => {
          this.$emit("update:isOpen", true);
          this.showErrors(error, "formCreateBusiness");
        });
    },
    onSetLogo(data) {
      this.form.logo = data;
    },
    onClose() {
      this.$emit("update:isOpen", false);
    },
    onChangeStatusModel(value) {
      if (!value) {
        this.onClose();
      }
    },
  },
};
</script>
