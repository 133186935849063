<template>
  <div>
    <div class="content-header">
      <img @click="goToMobile" src="@/assets/images/arrow-left.svg" alt="Logo de Geor Company" class="img-fluid filter icon-fill" width="24">
      <span class="content-header__title">
        Cambiar contraseña
      </span>
    </div>
    <v-row class="content-profile">
      <v-col style="width: 468; max-width: 468px;">
        <v-card style="box-shadow: none; background-color: white;">
          <v-card-text class="card-password">
            <v-alert v-if="messageBackend" type="info">{{
              messageBackend
            }}</v-alert>
            <ValidationObserver v-slot="{ invalid }" tag="div" ref="formChangePassword">
              <form @submit.prevent="onSubmit" autocomplete="off" class="form-password">
                <ValidationProvider v-if="!user.hasPassword" name="código de verificación" rules="required|digits:6"
                  v-slot="{ errors }" vid="code">
                  <v-text-field label="Código de verificación" v-model="form.code" :error-messages="errors" class="profile"
                    v-mask="'######'" :counter="6"></v-text-field>
                </ValidationProvider>
                <ValidationProvider v-if="user.hasPassword" name="contraseña anterior" rules="required|min:6|max:50"
                  v-slot="{ errors }" vid="old_password">
                  <v-text-field label="Contraseña actual" v-model="form.old_password" :error-messages="errors" class="profile" style="font-size: 14px !important;"
                    type="password"></v-text-field>
                </ValidationProvider>
                <ValidationProvider name="password" rules="required|min:8|password_strong|max:50" v-slot="{ errors }" vid="password">
                  <v-text-field label="Nueva contraseña" v-model="form.password" :error-messages="errors" :type="showPassword ? 'text' : 'password'" class="profile"
                  style="font-size: 14px !important;">
                    <template v-slot:append>
                      <img v-if="showPassword" src="@/assets/images/icon-eye.svg" alt="Ojo abierto" @click="showPassword = !showPassword" style="cursor: pointer;">
                      <img v-else src="@/assets/images/icon-eye-close.svg" alt="Ojo cerrado" @click="showPassword = !showPassword" style="cursor: pointer;">
                    </template>
                  </v-text-field>
                </ValidationProvider>
                <div>
                  <v-btn color="primary" rounded :disabled="invalid || loading" :loading="loading" type="submit"
                    class="me-5" depressed style="padding: 0px 10px; border-radius: 5px; height: 32px;"><span class="text-button-new">Cambiar contraseña</span></v-btn>
                </div>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
      form: {
        password: "",
        old_password: "",
      },
      messageBackend: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      user: (state) => state.user,
    }),
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.form.password = "";
        this.form.old_password = "";
        if (!this.user.hasPassword) {
          this.onVerifyPreviousChange();
        }
      }
    },
  },
  methods: {
    onVerifyPreviousChange() {
      this.$axios.post("users/send-code-change-password").then((response) => {
        this.messageBackend = response.data.message;
      });
    },
    onSubmit() {
      this.$axios
        .post("users/change-password", this.form)
        .then((response) => {
          const { data } = response;
          if (!this.user.hasPassword) {
            const { user } = this;
            user.hasPassword = true;
            this.$auth.saveUser(user);
            this.$store.dispatch("REFRESH_USER_DATA");
          }
          this.$toast.success(data.message);
          this.onClose();
        })
        .catch((error) => this.showErrors(error, "formChangePassword"));
    },
    goToMobile() {
      this.$router.push({ name: 'ProfileMobile' });
    },
    onClose() {
      this.messageBackend = null;
      this.$store.dispatch("SET_OPEN_MODAL_CHANGE_PASSWORD", false);
    },
  },
};
</script>
