<template>
  <div class="sub-navbar-container" style="position: relative;">
    <div class="sub-navbar-menu">
      <div class="sub-navbar-header">
        <p class="sub-navbar-title">
        Mi perfil
      </p>
      </div>

      <div style="width: 100%; margin-bottom: 20px;">
          <p class="subtitle-text" style="padding-block: 4px; margin-bottom: 0px !important; margin-top: 10px;">MI CUENTA</p>
          <div>
            <router-link  style="font-size: 14px; font-weight: 400;color:rgba(0, 0, 0, 0.6);  position: relative;" class="profile-sidebar-item"
          :class="computedClasses('/dashboard/profile/profile/')"
            :to="{ name: 'Profile' }">
            <div style="display: flex; align-items: center;" >
              <div class="rectangle"></div>
              <span>Configuración de perfíl</span>
            </div>
          </router-link>
          </div>
        </div>
        <div style="width: 100%;">
          <p class="subtitle-text"  style="padding-block: 4px; margin-bottom: 0px !important;">SEGURIDAD</p>
          <router-link style="font-size: 14px; font-weight: 400;color:rgba(0, 0, 0, 0.6);position: relative;" class="profile-sidebar-item"
          :class="computedClasses('/dashboard/profile/ChangePassword')" :to="{ name: 'ChangePassword' }">
          <div style="display: flex; align-items: center;" >
              <div class="rectangle"></div>
              <span>Cambiar contraseña</span>
            </div>
        </router-link>
        </div>
        <!-- <li>
        <a href="#">Mi equipo</a>
      </li>
      <li>
        <a href="#">Roles</a>
      </li>
      <li>
        <a href="#">Preferencias</a>
      </li>
      <li>
        <a href="#">Suscripciones</a>
      </li> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    computedClasses() {
      return (param) => ({
        'selected-hover': this.isSpecificRoute(param),
      });
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    isSpecificRoute(route) {
      return this.$route.path === route;
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
      this.redirectBasedOnScreenWidth();
    },
    redirectBasedOnScreenWidth() {
      if (this.screenWidth < 699) {
        this.$router.push({ name: 'ProfileMobile' });
      } else {
        this.$router.push('/dashboard/profile/profile/');
      }
    },
  },
};
</script>
