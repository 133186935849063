<template>
  <v-container>
    <div class="d-flex align-center mb-10 dashboard__title">
      <p class="mb-0 me-auto dashboard__title-text">Mis empresas</p>
      <template v-if="projects.length > 0">
        <v-btn color="success" depressed small dark @click="isOpenModalCreateBusiness = true">
          <i class="fa fa-plus"></i>
          <span class="ms-2">Crear empresa</span>
        </v-btn>
        <v-btn
          :outlined="viewMode === 'grid'"
          small
          depressed
          class="v-btn-box ms-8 me-3"
          @click="onSetViewMode('grid')"
        >
          <i class="fa f-size-1rem fa-th-large"></i>
        </v-btn>
        <v-btn
          small
          depressed
          :outlined="viewMode === 'list'"
          class="v-btn-box"
          @click="onSetViewMode('list')"
        >
          <i class="fa f-size-1rem fa-list"></i>
        </v-btn>
      </template>
    </div>
    <ProjectsNone v-if="projects.length < 1"></ProjectsNone>
    <template v-else>
      <v-row v-if="viewMode === 'grid'">
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="3"
          v-for="project in projects"
          :key="`col${project.id}`"
          class="mb-5"
        >
          <v-card class="pb-3 projects__mode-grid">
            <v-app-bar v-if="project.is_owner" flat color="rgba(0, 0, 0, 0)" absolute bottom>
              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="v-btn-project" depressed v-bind="attrs" v-on="on">
                    <v-icon size="18">fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="onEdit(project)">
                    <v-list-item-avatar max-width="20px" min-width="20px">
                      <v-icon size="14">fa-pen</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onShowUsers(project)">
                    <v-list-item-avatar max-width="20px" min-width="20px">
                      <v-icon size="14">fa-users-cog</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Usuarios</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="onDelete(project)">
                    <v-list-item-avatar max-width="20px" min-width="20px">
                      <v-icon size="14">fa-trash</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>
            <v-img
              :src="project.logo"
              :alt="project.name"
              class="img-fluid cursor-pointer"
              height="130"
              @click.native="onAutoLogin(project.domain)"
            ></v-img>
            <v-card-title class="pb-0">
              <span class="text-overflow">{{ project.name }}</span>
            </v-card-title>
            <span class="text-muted mx-4">@{{ project.slug }}</span>
          </v-card>
        </v-col>
      </v-row>
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th>NOMBRE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(project, ind) in projects" :key="`tr${ind}`">
              <td>
                <div class="div-horizontal-centered">
                  <v-btn text rounded @click="onAutoLogin(project.domain)">
                    <div class="text-center me-3" style="width: 70px">
                      <img
                        :src="project.logo"
                        :alt="project.name"
                        class="img-fluid"
                        style="max-witdh: 70px; max-height: 36px"
                      />
                    </div>
                    {{ project.name }}
                  </v-btn>
                </div>
              </td>
              <td class="text-right">
                <v-menu v-if="project.is_owner" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="black" icon v-bind="attrs" v-on="on">
                      <v-icon size="18">fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="onEdit(project)">
                      <v-list-item-avatar max-width="20px" min-width="20px">
                        <v-icon size="14">fa-pen</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onDelete(project)">
                      <v-list-item-avatar max-width="20px" min-width="20px">
                        <v-icon size="14">fa-trash</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Eliminar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <iframe :src="domainSelected" frameborder="0" width="100%" height="500"></iframe>
    <ModalCreateBusiness
      @onAddProject="onAddProject"
      :is-open.sync="isOpenModalCreateBusiness"
    ></ModalCreateBusiness>
    <ModalSuggetAddEmail :is-open.sync="openModalSuggetAddEmail"></ModalSuggetAddEmail>
    <ModalEditBusiness
      :isOpen.sync="openModalEdit"
      :project="project"
      @onEditProject="onUpdateProject"
    ></ModalEditBusiness>
    <ModalDelete
      :show.sync="openModalDelete"
      :title="titleModalDelete"
      :message="messageDelete"
      @onDeleteProject="onDestroy"
    ></ModalDelete>
    <ModalWarning :show.sync="openModalWarning" title="ATENCIÓN" :message="messageWarning">
      <template slot="btns">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn depressed color="primary" @click="onChangePassword"
              >cambiar mi contraseña ahora!!!</v-btn
            >
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn depressed @click="onCancel">Más tarde lo hago</v-btn>
          </v-col>
        </v-row>
      </template>
    </ModalWarning>
    <ModalProjectUsers :isOpen.sync="openModalProjectUsers" :project="project"></ModalProjectUsers>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ModalSuggetAddEmail from "@/components/profile/ModalSuggestAddEmail.vue";
import ModalWarning from "@/components/partials/ModalWarning.vue";
import ModalDelete from "@/components/partials/ModalDelete.vue";
import ProjectsNone from "./ProjectsNone.vue";
import ModalCreateBusiness from "./ModalCreateBusiness.vue";
import ModalEditBusiness from "./ModalEditBusiness.vue";
import ModalProjectUsers from "../../components/projects/Users.vue";

export default {
  components: {
    ProjectsNone,
    ModalCreateBusiness,
    ModalSuggetAddEmail,
    ModalEditBusiness,
    ModalDelete,
    ModalWarning,
    ModalProjectUsers,
  },
  data() {
    return {
      openModalProjectUsers: false,
      openModalSuggetAddEmail: false,
      baseUrl: process.env.VUE_APP_URL_WEB,
      projects: [],
      isOpenModalCreateBusiness: false,
      viewMode: "grid",
      openModalEdit: false,
      project: null,
      openModalDelete: false,
      titleModalDelete: "Eliminar proyecto",
      messageDelete: "",
      openModalWarning: false,
      messageWarning:
        "Se ha detectado que la contraseña que estás usando es débil, se recomienda cambiar de contraseña lo más pronto posible.",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      domainSelected: (state) => state.domainSelected,
    }),
  },
  mounted() {
    this.$axios.get("projects/only/facturacion").then((response) => {
      this.projects = response.data.data;
    });
    this.viewMode = localStorage.getItem("viewModeProject") || "grid";
    const closeModalSuggeted = localStorage.getItem("closeModalSuggeted");
    if (!closeModalSuggeted) {
      if (!this.user.hasEmail) {
        this.openModalSuggetAddEmail = true;
      }
    }

    this.openModalWarning = this.user.changePassword;
  },
  methods: {
    onShowUsers(project) {
      this.project = { ...project };
      this.openModalProjectUsers = true;
    },
    onChangePassword() {
      this.onCancel();
      this.$store.dispatch("SET_OPEN_MODAL_CHANGE_PASSWORD", true);
    },
    onCancel() {
      const user = { ...this.user };
      user.changePassword = false;
      this.$auth.saveUser(user);
      this.$store.dispatch("REFRESH_USER_DATA");
      this.openModalWarning = false;
    },
    onDestroy() {
      this.$axios.delete(`projects/${this.project.id}/delete`).then((response) => {
        this.openModalDelete = false;
        this.projects = this.projects.filter((p) => p.id !== this.project.id);
        this.project = null;
        this.$toast.success(response.data.message);
      });
    },
    onDelete(project) {
      this.messageDelete = `Esta acción no se puede deshacer, ¿estás seguro de eliminar el proyecto ${project.name}?`;
      this.openModalDelete = true;
      this.project = { ...project };
    },
    onUpdateProject(project) {
      this.projects = this.projects.map((p) => {
        if (p.id === project.id) {
          return project;
        }
        return p;
      });
    },
    onEdit(project) {
      this.project = { ...project };
      this.openModalEdit = true;
    },
    onSetViewMode(mode) {
      localStorage.setItem("viewModeProject", mode);
      this.viewMode = mode;
    },
    onAutoLogin(domain) {
      this.$store.dispatch("ON_AUTO_LOGIN_DEMO", domain);
    },
    onAddProject(project) {
      this.projects.push(project);
    },
  },
};
</script>
