<template>
  <v-dialog v-model="show" max-width="390" persistent>
    <v-card style="display: flex; padding: 24px;flex-direction: column;gap: 24px;border-radius: 10px">
      <div style="display: flex;flex-direction: column;gap: 8px;">
        <div class="text-center pt-10" style="padding-top: 0px !important;display: flex;align-items: flex-start;">
        <img
          src="@/assets/images/alert-trash.svg"
          alt="Icono eliminar"
          class="img-fluid"
          width="48"
          height="48"
        />
        </div>
        <div class="text-start ">
          <strong  class="text-h6" style="font-weight: 700;font-size: 14px !important;">{{ title }}</strong>
          <v-card-text class="text--secondary" style="padding: 8px 0px 0px;font-weight: 400; font-size: 12px !important;">{{ message }}</v-card-text>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end;gap:10px;">
        <v-btn
          style="height: 32px; border-radius: 5px;border: 1px solid var(--Secondary-Outline-border, #E0E0E0); background-color: transparent;
          padding-inline:10px !important;margin-right:0px !important"
          :disabled="loading"
          depressed
          @click="onCancel"
          ><span class="text-button-new">Cancelar</span></v-btn
        >
        <v-btn
          style="height: 32px; border-radius: 5px;margin-right:0px !important;margin-left: 0px !important;padding-inline:10px !important;"
          :disabled="loading"
          :loading="loading"
          depressed
          color="error"
          @click="onAccept"
          ><span class="text-button-new">Eliminar</span></v-btn
        >
        </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    onCancel() {
      this.$emit("update:show", false);
    },
    onAccept() {
      this.$emit("onDeleteProject");
    },
  },
};
</script>
